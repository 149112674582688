import { useState, useEffect } from 'react';
import { algoliasearch } from 'algoliasearch';

// Initialization of Algolia client
const client = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_API_KEY);
const indexName = 'ottomotors';

// Custom hook for fetching data from Algolia
const useAlgoliaData = ({ query = '', minLength = 0 }) => {
  const [algoliaData, setAlgoliaData] = useState(null);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchDataFromAlgolia = async () => {
      setLoading(true);
      try {
        if (query === '') {
          setAlgoliaData(null);
          setLoading(false);
          return;
        }

        if (query.length > minLength) {
          const { hits } = await client.searchSingleIndex({
            indexName,
            searchParams: { query },
          });
          setAlgoliaData(hits);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data from Algolia:', error);
      }
    };

    fetchDataFromAlgolia();
  }, [query, minLength]);

  return { data: algoliaData, loading };
};

export default useAlgoliaData;
