import React, { useEffect } from "react";
import cn from "classnames";
import { PortableTextRenderer, SVG } from "@ottomotors/ottomotors-common/components";
import { useAccordion } from "@ottomotors/ottomotors-common/hooks";
import * as styles from "./styles.module.scss";

interface IProps {
  question: string;
  answer: any;
  isExpanded: boolean;
}

const FaqAccordion = ({ question, answer, isExpanded }: IProps) => {
  const { containerHeight, contentRef, setIsExpanded } = useAccordion();

  useEffect(() => {
    setIsExpanded(isExpanded);
  }, [isExpanded]);

  return (
    <div className={cn(styles.container, { [styles.expanded]: isExpanded })}>
      <div className={cn("h4", styles.header)}>
        <span className={styles.question}>{question}</span>
        <div className={styles.icon}>
          <SVG svg="chevronDown" />
        </div>
      </div>
      <div
        className={styles.contentContainer}
        style={{
          height: containerHeight,
        }}
      >
        <article ref={contentRef} className={styles.answer}>
          <PortableTextRenderer rawText={answer} />
        </article>
      </div>
    </div>
  );
};

export default FaqAccordion;
