import { useLayoutEffect } from 'react';

const useLockBodyScroll = (lock = true) => {
  useLayoutEffect(() => {
    const originalStyle = {
      overflow: document.body.style.overflow,
      position: document.body.style.position,
      top: document.body.style.top,
      left: document.body.style.left,
      width: document.body.style.width,
    };

    let scrollPosition = 0;

    if (lock) {
      scrollPosition = window.scrollY;

      // Prevent scrolling by hiding overflow and fixing position
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollPosition}px`;
      document.body.style.left = '0';
      document.body.style.width = '100%'; // Prevent layout shifts
    } else {
      // Restore the original styles
      document.body.style.overflow = originalStyle.overflow;
      document.body.style.position = originalStyle.position;
      document.body.style.top = originalStyle.top;
      document.body.style.left = originalStyle.left;
      document.body.style.width = originalStyle.width;

      // Restore the scroll position
      window.scrollTo(0, parseInt(originalStyle.top || '0') * -1);
    }

    return () => {
      if (lock) {
        // Restore original styles and scroll position on unmount
        document.body.style.overflow = originalStyle.overflow;
        document.body.style.position = originalStyle.position;
        document.body.style.top = originalStyle.top;
        document.body.style.left = originalStyle.left;
        document.body.style.width = originalStyle.width;
        window.scrollTo(0, scrollPosition);
      }
    };
  }, [lock]);
};

export default useLockBodyScroll;
