import React, { useState } from "react";
import cn from "classnames";
import {
  SVG,
  Button,
  LayoutProvider,
  UniversalLink,
} from "@ottomotors/ottomotors-common/components";
import { Link } from "gatsby";
import Card from "./components/Card";
import { useApp, useSiteSettings } from "@ottomotors/ottomotors.com/hooks";
import Search from "./components/Search";

import * as styles from "./styles.module.scss";

const Header = () => {
  const siteSettings = useSiteSettings();
  const {
    header: { items },
  } = siteSettings || {};
  const { isMenuOpen, setIsMenuOpen, isBannerVisible } = useApp();

  const [hoveredItem, setHoveredItem] = useState("");

  return (
    <header
      className={cn(styles.container, {
        [styles.bannerActive]: !!siteSettings?.banner?.showBanner && isBannerVisible,
        [styles.mobileActive]: isMenuOpen,
      })}
    >
      <LayoutProvider grid>
        <div className={styles.content}>
          <Link className={styles.logo} to="/">
            <figure className={styles.logo}>
              <SVG svg="rockwellOttoLogo" />
            </figure>
          </Link>

          {/* mobile */}
          <div className={styles.mobileButtonContainer}>
            <Search />
            <div className={cn(styles.contactButton, styles.tablet)}>
              <Button
                text="Contact Us"
                to={`/contact/`}
                theme="lightOnDark"
                iconRight="arrowRight"
              />
            </div>
            <button
              className={styles.mobileButton}
              type="button"
              onClick={() => setIsMenuOpen((prev) => !prev)}
            >
              <figure className={styles.mobileButtonSvg}>
                {(isMenuOpen && (
                  <SVG className={styles.crossSvg} svg="x" />
                )) || <SVG className={styles.ellipsisSvg} svg="ellipsis" />}
              </figure>
            </button>
          </div>

          {/* desktop */}
          <div className={styles.desktopMenu}>
            <ul className={styles.desktopMenuList}>
              {items?.[0] &&
                items.map((item) => {
                  const {
                    _key: parentItemKey,
                    link,
                    links,
                    title,
                  } = item || {};

                  return (
                    <li
                      key={`header-item-${parentItemKey}`}
                      className={cn(styles.desktopMenuItem)}
                      onMouseEnter={() => setHoveredItem(parentItemKey)}
                    >
                      <>
                        {(link?.[0] && (
                          <UniversalLink
                            className={styles.desktopMenuLabel}
                            link={{ link: link, text: title }}
                            variant="text"
                            theme="lightOnDark"
                          />
                        )) || (
                          <p className={cn(`label`, styles.desktopMenuLabel)}>
                            {title}
                          </p>
                        )}

                        {links?.[0] && (
                          <ul
                            key={`desktop-menu-${parentItemKey}-inner-links`}
                            className={cn(styles.desktopMenuItemInner, {
                              [styles.desktopMenuItemCards]:
                                links[0]._type ===
                                "ottomotors.header.item.card",
                            })}
                          >
                            {links.map((item) => {
                              const { _key, _type, text, link, params } =
                                item || {};

                              const universalLink = {
                                link,
                                params,
                                text,
                              };

                              return (
                                <li
                                  key={`desktop-menu-${_key}-inner-link`}
                                  className={cn(
                                    styles.desktopMenuItemInnerLink,
                                    {
                                      [styles.desktopMenuItemInnerCard]:
                                        _type === "ottomotors.header.item.card",
                                    }
                                  )}
                                >
                                  {(_type === "ottomotors.header.item.card" && (
                                    <Card data={item} />
                                  )) || (
                                    <UniversalLink
                                      className={styles.textLink}
                                      link={universalLink}
                                      variant="text"
                                      theme="lightOnDark"
                                      typestyle="b1"
                                      alignText="left"
                                    />
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </>
                    </li>
                  );
                })}
            </ul>
            <div className={styles.searchAndButton}>
              <Search />
              <div className={styles.contactButton}>
                <Button
                  text="Contact Us"
                  to={`/contact/`}
                  theme="lightOnDark"
                  iconRight="arrowRight"
                  />
              </div>
            </div>
          </div>
        </div>
      </LayoutProvider>
    </header>
  );
};

export default Header;
