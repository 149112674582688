import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "@reach/router"
import cn from "classnames";
import { LoadingSpinner } from "@ottomotors/ottomotors-common/components";
import Portal from "../../../Portal";
import { useAlgoliaData } from "@ottomotors/ottomotors.com/hooks";
import { useLockBodyScroll } from "@ottomotors/ottomotors.com/hooks";
import { Button } from "@ottomotors/ottomotors-common/components";
import { getUniversalLinkUrl } from "../../../../../../common/utils/index";

import * as styles from "./styles.module.scss";

const Search = (props) => {
  const { className } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false); // shows the search dropdown
  const searchInputRef = useRef(null);

  const location = useLocation();

  const { loading, data: algoliaData } = useAlgoliaData({
    query: searchTerm,
    minLength: 2,
  });

  useEffect(() => {
    setShowDropdown(false);
  }, []);

  useEffect(() => {
    setShowDropdown(false);
  }, [location.pathname]);

  useLockBodyScroll(showDropdown);

  return (
    <div className={className}>
      <Button
        className={cn(styles.searchButton, {
          [styles.dropdownActive]: showDropdown,
        })}
        variant="iconButton"
        iconButtonSize="large"
        icon="lookingGlass"
        onClick={() => setShowDropdown(!showDropdown)}
        theme="lightOnDark"
      />
      {showDropdown && (
        <Portal className={styles.portalBg}>
          <section className={styles.section}>
            <div className={styles.contentContainer}>
              <div className={styles.searchBarAndTitle}>
                <div className={styles.titleAndClose}>
                  <h2 className={cn(`h2`, styles.title)}>Search</h2>
                  <Button
                    className={styles.button}
                    variant="iconButton"
                    iconButtonSize="regular"
                    icon="x"
                    onClick={() => setShowDropdown(false)}
                    theme="lightOnDark"
                  />
                </div>
                <label htmlFor="search" className={styles.searchBar}>
                  <input
                    ref={searchInputRef}
                    type="text"
                    id="search"
                    className={styles.input}
                    value={searchTerm}
                    onChange={(event) => setSearchTerm(event.target.value)}
                    placeholder="Type to search"
                  />
                </label>
              </div>
              {algoliaData && (
                <Results
                  loading={loading}
                  algoliaData={algoliaData}
                  searchTerm={searchTerm}
                />
              )}
            </div>
          </section>
        </Portal>
      )}
    </div>
  );
};

export default Search;

const Results = ({ loading, algoliaData, searchTerm }) => {
  const resultItemsRef = useRef();

  useEffect(() => {
    const calculateTopForResults = () => {
      const resultsDiv = resultItemsRef.current;
      if (!resultsDiv) return;
      const { top } = resultsDiv.getBoundingClientRect();
      const height = window.innerHeight - top;
      resultsDiv.style.maxHeight = `${height}px`;
    };
    requestAnimationFrame(calculateTopForResults);
    window.addEventListener("resize", calculateTopForResults);
    return () => {
      window.removeEventListener("resize", calculateTopForResults);
    };
  }, [algoliaData]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <LoadingSpinner size={40} className={styles.spinner} />
      </div>
    );
  }

  if (algoliaData.length === 0) {
    return <p className={styles.noResults}>{`No results found for "${searchTerm}".`}</p>;
  }

  return (
    <div className={styles.resultItems} ref={resultItemsRef}>
      {algoliaData?.map((result) => (
        <SearchResult key={result._id} result={result} />
      ))}
    </div>
  );
};

const SearchResult = ({ result }) => {
  const url = getUniversalLinkUrl([result], true);

  return (
    <a href={url} className={styles.result} key={result?.title}>
      <div className={styles.textContainer}>
        <span className={cn(`label`, styles.resultTagline)}>
          {result?.tagline}
        </span>
        <span className={cn(`h4`, styles.resultTitle)}>{result?.title}</span>
      </div>
      <Button
        className={styles.resultButton}
        variant="iconButton"
        iconButtonSize="large"
        icon="arrowRight"
        theme="lightOnDark"
        display
      />
    </a>
  );
};
