import React, { useEffect, useRef } from "react";
import cn from "classnames";
import {
  Button,
  LayoutProvider,
  SVG,
} from "@ottomotors/ottomotors-common/components";
import { GatsbyImage } from "gatsby-plugin-image";
import { useApp } from "@ottomotors/ottomotors.com/hooks";

import * as styles from "./styles.module.scss";

const Lightbox = () => {
  const { lightboxSource, setLightboxSource } = useApp();
  const videoRef = useRef();

  const isValidVideoURL = (url) => {
    // Define a list of valid video file extensions

    const validExtensions = ["mp4", "webm", "ogg", "ogv", "avi", "mov", "mkv"];

    // Use a regular expression to extract the file extension from the URL
    const extensionMatch = url.match(/\.([a-zA-Z0-9]+)(\?.*)?$/);

    if (extensionMatch && extensionMatch[1]) {
      // Get the extracted file extension (lowercase) and check if it's in the validExtensions list
      const fileExtension = extensionMatch[1].toLowerCase();
      return validExtensions.includes(fileExtension);
    }

    // If the URL doesn't have a valid file extension, return false
    return false;
  };

  const isVideo = isValidVideoURL(lightboxSource);

  useEffect(() => {
    if (!isVideo || !videoRef?.current) return;

    if (lightboxSource) {
      videoRef.current.play();
    } else {
      videoRef.current.currentTime = 0; // Reset video to the beginning
      videoRef.current.pause();
    }
  }, [lightboxSource]);

  return (
    <div
      className={cn(styles.container, {
        [styles.visible]: lightboxSource?.length > 0,
      })}
    >
      <LayoutProvider className={cn(styles.grid, {[styles.video]: isVideo})} grid>
        <div className={styles.media}>
          {!isVideo && (
            <div className={styles.closeButton}>
              <Button
                className={styles.button}
                variant="iconButton"
                iconButtonSize="regular"
                icon="x"
                onClick={() => setLightboxSource("")}
                theme="darkOnLight"
              />
            </div>
          )}

          {(isVideo && (
            <div className={styles.video}>
              <div className={styles.closeButton}>
                <Button
                  className={styles.button}
                  variant="iconButton"
                  iconButtonSize="regular"
                  icon="x"
                  onClick={() => setLightboxSource("")}
                  theme="darkOnLight"
                />
              </div>
              <video ref={videoRef} controls>
                <source src={lightboxSource} type="video/mp4" />
              </video>
            </div>
          )) || (
            <>
              {lightboxSource.asset?.gatsbyImageData && (
                <GatsbyImage
                  image={lightboxSource.asset?.gatsbyImageData}
                  alt={item.image.altText || "OTTO Motors"}
                />
              )}
            </>
          )}
        </div>
      </LayoutProvider>
    </div>
  );
};

export default Lightbox;
