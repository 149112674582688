// extracted by mini-css-extract-plugin
export var close = "styles-module--close--6e6d7";
export var contentContainer = "styles-module--contentContainer--50efb";
export var desktop = "1340px";
export var dropdownActive = "styles-module--dropdownActive--53188";
export var giant = "2200px";
export var input = "styles-module--input--be888";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var loading = "styles-module--loading--3b8e5";
export var mobile = "400px";
export var noResults = "styles-module--noResults--6c317";
export var portalBg = "styles-module--portalBg--d35ed";
export var result = "styles-module--result--2e794";
export var resultButton = "styles-module--resultButton--51d57";
export var resultItems = "styles-module--resultItems--db0c9";
export var resultTagline = "styles-module--resultTagline--cc14a";
export var resultTitle = "styles-module--resultTitle--2e57e";
export var resultsFound = "styles-module--resultsFound--ca8a8";
export var searchBar = "styles-module--searchBar--76e49";
export var searchBarAndTitle = "styles-module--searchBarAndTitle--88050";
export var searchButton = "styles-module--searchButton--9fe0f";
export var section = "styles-module--section--d8677";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var spinner = "styles-module--spinner--11f50";
export var tablet = "768px";
export var textContainer = "styles-module--textContainer--960b5";
export var title = "styles-module--title--0774b";
export var titleAndClose = "styles-module--titleAndClose--0fe88";