import React, { useRef } from "react";
import { SVG, TSvg } from "@ottomotors/ottomotors-common/components";
import cn from "classnames";
import { Link } from "gatsby";
import * as styles from "./styles.module.scss";

export interface IButton {
  // Appearance
  text?: string;
  variant?: "primary" | "secondary" | "text" | "iconButton";
  theme?: "lightOnDark" | "darkOnLight";
  typestyle?: "button" | "b1";
  alignText?: "left" | "center";
  icon?: TSvg;
  iconButtonSize?: "small" | "regular" | "large";
  iconLeft?: TSvg;
  iconRight?: TSvg;
  animateOnHover?: boolean;
  disabled?: boolean;
  fluid?: boolean;
  loading?: boolean;
  isHovered?: boolean;
  // Functionality
  href?: string;
  to?: string;
  buttonType?: "button" | "reset" | "submit";
  display?: boolean;
  onClick?: () => void;
  className?: string;
}

const Button = ({
  text,
  variant = "primary",
  buttonType = "button",
  theme = "darkOnLight",
  typestyle = "button",
  alignText = "center",
  isHovered = false,
  to,
  href,
  icon,
  iconButtonSize,
  iconLeft,
  iconRight,
  animateOnHover = false,
  disabled,
  fluid,
  loading,
  display,
  onClick,
  className,
}: IButton) => {
  const buttonRef = useRef(null);

  let BtnElement: any;
  if (display) BtnElement = "div";
  else if (to) BtnElement = Link;
  else if (href) BtnElement = "a";
  else BtnElement = "button";

  return (
    <BtnElement
      ref={buttonRef}
      type={to || href ? "" : buttonType}
      href={href}
      target={href ? "_blank" : null}
      rel={href ? "noreferrer noopener" : null}
      disabled={disabled}
      onClick={() => onClick && onClick()}
      tabIndex={disabled ? "-1" : 0}
      to={to}
      className={cn(styles.button, className, styles[variant], {
        [styles.fluid]: fluid,
        [styles.loading]: loading,
        [styles.inverted]: theme === "lightOnDark",
        [styles.isHovered]: isHovered,
        [styles[iconButtonSize]]: iconButtonSize,
      })}
    >
      {variant === "iconButton" && (
        <div className={cn(styles.content, typestyle)}>
          <SVG svg={icon} />
        </div>
      )}

      {variant !== "iconButton" && (
        <div
          className={cn(styles.content, className, {
            [styles.alignLeft]: alignText === "left",
          })}
        >
          {iconLeft && (
            <figure
              className={cn(styles.icon, { [styles.iconLeft]: animateOnHover })}
            >
              <SVG svg={iconLeft} />
            </figure>
          )}

          <span
            className={cn(typestyle || "button", {
              [styles.contentText]: animateOnHover,
            })}
          >
            {text}
          </span>

          {iconRight && (
            <figure
              className={cn(styles.icon, {
                [styles.iconRight]: animateOnHover,
              })}
            >
              <SVG svg={iconRight} />
            </figure>
          )}
        </div>
      )}

      {!display && (
        <div className={styles.loaderContainer}>
          <div className={styles.loader} />
        </div>
      )}
    </BtnElement>
  );
};

export default Button;
