import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

// Used to render a component outside of the normal DOM hierarchy
const Portal = (props) => {
  const { children, elementId = '___gatsby', className } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) return null;

  const appRoot = document.getElementById(elementId);

  return ReactDOM.createPortal(<div className={className}>{children}</div>, appRoot);
};

export default Portal;
